<template>
  <div
            ref="containerRef"

  >
    <div v-for="(item , index) in element.config.elements" :key="index">

      <v-divider
          v-if="index === 0 && element.config.display_line"
          :style="`border-color : ${BLOCK_COLOR_PARAM(element , `accordion_line` , blockStyle )}`"
      />

      <div  class="d-flex justify-space-between align-center pointer"
           :style="`padding : ${paddings[element.config.size]} 0; `"
      >

        <h3 :style="BLOCK_TEXT_CSS(element , blockStyle , 'accordion' , 'heading')"  >
          {{ item.title }}
        </h3>

        <v-icon
            :color="BLOCK_COLOR_PARAM(element , `accordion_expand` , blockStyle )"
            large> {{  `mdi-${item.expanded ? 'minus' : 'plus' }`  }} </v-icon>

      </div>


      <v-expand-transition>
        <div v-show="item.expanded" >
          <avalon-html-viewer
              :text="item.text"
              :block-style="blockStyle"
              :update-trigger="trigger"
          />
          <div :style="`padding-bottom: ${paddings[element.config.size]}`" />
        </div>


      </v-expand-transition>

      <v-divider
          v-if="element.config.display_line"
          :style="`border-color : ${BLOCK_COLOR_PARAM(element , `accordion_line` , blockStyle )}`"
      />

    </div>

  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "elementAccordionEditor",
  props : {
    value : {
      type : Object,
      default() { return {} }
    },
    blockStyle : {
      type : String
    }
  },
  data() {
    return {
      height : 0,
      element : { config : { elements : []}},
      paddings : {
        small : '13px',
        medium : '21px',
        large : '33px'
      },
      uuid : null,
      firstLoad : false,
      trigger : 0
    }
  },
  computed : {
    siteConfig() {
      return this.$store.state.avalon.config
    },
    domElement() {
      return this.$refs.containerRef
    },
    elementStyle() {
      let style = ''

      if (this.element.config.outlined) {
        style += `border : ${this.element.config.outline_width || 1}px solid ${this.GET_AVALON_COLOR(this.element.config.background_color)} !important;`
      }
      if (this.element.config.rounded) {
        style += `border-radius : ${this.element.config.rounded}px;`
      }

      style += this.GET_CLIPPING_MASK(this.element.config.mask || null , this.element.config.mask_resize)

      return style
    }
  },
  watch : {
    height(value) {
      this.updateHeight()
      if ( value > 0 ) {
        setTimeout(()=> {
          this.getHeight()
          this.firstLoad =  false
        } , 400)
      }
      setTimeout(  this.updateHeight , 300 )
    },
    'siteConfig.fonts.elements' : {
      handler() {
        setTimeout( () => {
          this.getHeight()
          this.updateHeight()
        }   , 300)
      },
      deep : true
    },
    'element.config' : {
      handler(value,old) {
        if ( !this.EQ(value,old) ) {
          setTimeout(   () => {
            this.getHeight()
            this.updateHeight()
          } , 300)
          this.handleConfigChange()
        }

      },
      deep : true
    },
    value : {
      handler() {

        if ( this.value.width !== this.element.width ){
          setTimeout(this.getHeight , 30)
        }

        if ( JSON.parse(JSON.stringify(this.value)) !== JSON.parse(JSON.stringify(this.element)) ) {
          this.element = JSON.parse(JSON.stringify(this.value))
        }
      },
      deep : true
    }

  },
  methods : {
    ...mapActions('avalonPage', ['EDIT_ELEMENT']),

    updateHeight() {
      this.getHeight()
      setTimeout(() => {
        this.$emit('actual-height-update' , this.height)
        this.$emit('update-height', this.height )
      }, 300)
    },
    getHeight() {
      let height = this.domElement.clientHeight
      this.height = height
    },
    titleTextStyle(fontStyle , isHeading = true) {

      let style = ''

      let fontGroup = ['p','h5','h6'].includes(fontStyle) ? 'paragraphs' : 'headings'

      let elementType = isHeading ? 'heading' : 'text'

      style += `color : ${this.CONFIG_PARAM_COLOR('styles' , this.blockStyle , `accordion_${elementType}` ) }; `
      style += `font-family : ${this.CONFIG_PARAM('fonts', fontGroup ,'family')};`
      style += `font-size : ${this.CONFIG_PARAM("fonts" , fontGroup , "size" , fontStyle )}px; `
      style += `font-weight : ${this.CONFIG_PARAM("fonts" , fontGroup , "weight" )} ;`
      style += `text-transform : ${this.CONFIG_PARAM("fonts" , fontGroup , "case" )} ;`
      style += `line-height : ${this.CONFIG_PARAM("fonts" , fontGroup , "lineHeight" )} + '%' ;`
      style += `letter-spacing : ${this.CONFIG_PARAM("fonts" , fontGroup , "spacing")  || 0}px  ;`
      style += `font-style : ${this.CONFIG_PARAM("fonts" , fontGroup , "style")}  ;`

      return style
    },
    handleConfigChange() {
      this.trigger++
      if ( this.element.config.elements.length > 0 ) {
        if ( this.element.config.expand_first ) {
          this.element.config.elements[0].expanded = true
        } else {
          this.element.config.elements[0].expanded = false
        }
      }
    },

  },
  beforeMount() {
    this.uuid = this.$uuid.v4()
  },
  mounted(){
    if ( this.value ) {
      this.element = JSON.parse(JSON.stringify(this.value))
      this.$emit('input',this.element)
    }
  }
}
</script>

<style scoped>

</style>